body {
    margin: 0;
    padding: 0;
    background-color: #38243F;
    min-height: 100vh;
}

/* Header Container */
.header-container {
    width: 100vw;
    height: 50vh;
    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.space-gif {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}

.text-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #dac685;
    text-align: center;
    z-index: 2;
}

.intro {
    font-family:'Courier New', Courier, monospace;
}

.my-name {
    font-family: autography;
    font-size: 8vh;
    position: relative;
}

/* Selfie and Paragraph */
.intro-container {
    margin-top: 30px;;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

/* Section Styling */
.section-container {
    width: 85%;
    display: flex;
    flex-direction: column; /* stack vertically: title above cards */
    align-items: center;     /* center content horizontally */
    padding: 20px 10vh;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

}

.section-title {
    color: #dac685;
    font-size: 1.8rem;
    margin-bottom: 20px; /* spacing below the title */
    text-align: center;
}

footer {
    display: flex;
    justify-content: center;
    text-align: center;
    color:#dac685;
}

/*
.card-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;   /* allows wrapping on smaller screens 
    gap: 20px;         /* spacing between cards 
    width: 100%;
}

*/
/*
.section-container {
    width: 75%;
    height: auto;
    align-items: center;
    display: flex;
    flex-direction:row;
    justify-content: space-between;
    margin-left: 10vh;
    margin-right: 10vh;
    padding-top: 15px;
    padding-bottom: 15px;
    color: #38243F;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
.section-title {
    color:#dac685;
    top: 0;
}

.card {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}
*/

/*
.home-container {
    background-color: #38243F;
    height: 100vh;
    width: 90%; 
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    color: #dac685;
    
    display: flex;
    flex-direction: column;
    
}

.header-container {
    width: 100vw; 
    max-height: 33vh; 
    overflow: hidden;
    margin: 0;
    padding: 0;
    background-image: url("../img/EricaAndersonSpaceGif.gif");
}

.space-gif {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
    min-width: 100%;
}



@media (max-width: 768px) {
    .home-container {
        width: 100%;
        padding: 10px;
    }
}

.my-name {
    font-family: adelia;
    position: relative;
}

.intro-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
*/

.my-selfie {
    height: 100%;
    width: 300px;
    border: 5px solid #dac685;
    border-radius: 25px;
}

.paragraph-container {
    background-color: #EFD3C5;
    color: #56182C;
    display: flex;
    justify-items: center;
    height: auto;
    width: 50%;
    border-radius: 25px;
    padding: 15px;
}


